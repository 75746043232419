// libraries
import React, { ReactNode, useEffect, useState } from 'react';

import { styled, css } from 'style2';

import useIsVisible from 'hooks/useIsVisible';

interface Props {
  className?: string;
  duration?: number;
  delay?: number;
  scale?: 'large' | 'small';
  visible?: boolean;
  children: ReactNode;
}

export default function ScaleIn(props: Props) {
  const {
    children,
    className = '',
    duration = 1000,
    delay = 0,
    scale = 'large'
  } = props;
  const animation = scale === 'large' ? appearLarge : appearSmall;

  const [ref, visible] = useIsVisible<HTMLDivElement>();
  const isVisible = props.visible !== undefined ? props.visible : visible;

  const [play, setPlay] = useState(isVisible);

  useEffect(() => {
    if (isVisible) setPlay(true);
  }, [isVisible]);

  return (
    <Wrapper
      ref={ref}
      className={className.toString()}
      css={{
        animation: play ? `${animation} forwards` : undefined,
        animationDuration: `${duration}ms`,
        animationDelay: `${delay}ms`
      }}
    >
      {children}
    </Wrapper>
  );
}

const appearLarge = css.keyframes({
  '0%': { transform: 'scale(0)' },
  '16%': { transform: 'scale(1.32)' },
  '28%': { transform: 'scale(0.87)' },
  '44%': { transform: 'scale(1.05)' },
  '59%': { transform: 'scale(0.98)' },
  '73%': { transform: 'scale(1.01)' },
  '88%': { transform: 'scale(1)' },
  '100%': { transform: 'scale(1)' }
});

const appearSmall = css.keyframes({
  '0%': { transform: 'scale(0)' },
  '16%': { transform: 'scale(1.04)' },
  '28%': { transform: 'scale(0.98375)' },
  '44%': { transform: 'scale(1.00625)' },
  '59%': { transform: 'scale(0.9975)' },
  '73%': { transform: 'scale(1.00125)' },
  '88%': { transform: 'scale(1)' },
  '100%': { transform: 'scale(1)' }
});

const Wrapper = styled('div', {
  display: 'inline-block',
  transform: 'scale(0)',
  backfaceVisibility: 'hidden',
  willChange: 'transform'
});
