// libraries
import React, { forwardRef, ReactNode } from 'react';

import { styled } from 'style2';

interface Props {
  className?: string;
  title: string;
  children: ReactNode;
  align?: 'left' | 'right';
  valign?: 'center' | 'top';
  graphic: ReactNode;
  graphicHeight?: string;
}

export default forwardRef<HTMLDivElement, Props>(function Highlight(
  props,
  ref
) {
  const {
    className,
    title,
    children,
    align = 'left',
    valign = 'top',
    graphic,
    graphicHeight
  } = props;

  return (
    <HighlightWrapper
      ref={ref}
      className={className}
      css={{
        gridTemplateRows: `${graphicHeight || '1fr'} 1fr`,
        full: { gridTemplateRows: graphicHeight || 'auto' }
      }}
      valign={valign}
    >
      <HighlightGraphics align={align} css={{ height: graphicHeight }}>
        {graphic}
      </HighlightGraphics>
      <HighlightContent align={align}>
        <HighlightTitle>{title}</HighlightTitle>
        {children}
      </HighlightContent>
    </HighlightWrapper>
  );
});

const HighlightWrapper = styled('section', {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr',
  columnGap: '2rem',
  justifyContent: 'center',
  medium: {
    rowGap: '6rem'
  },
  large: {
    gridTemplateColumns: '69.4rem'
  },
  full: {
    rowGap: '6rem',
    gridTemplateColumns: 'repeat(2, 59.2rem)'
  },
  variants: {
    valign: {
      top: {},
      center: {
        alignItems: 'center'
      }
    }
  }
});

const HighlightGraphics = styled('div', {
  position: 'relative',
  gridRow: 1,
  variants: {
    align: {
      left: {
        full: {
          gridColumn: 2
        }
      },
      right: {
        gridColumn: 1
      }
    }
  }
});

const HighlightContent = styled('div', {
  gridRow: 2,
  textAlign: 'center',
  variants: {
    align: {
      left: {
        fill: {
          gridColumn: 1
        }
      },
      right: {
        full: {
          gridColumn: 2
        }
      }
    }
  },
  full: {
    gridRow: 1,
    textAlign: 'left'
  }
});

const HighlightTitle = styled('h2', {
  fontSize: '$h3',
  lineHeight: '$h3',
  fontWeight: 'bold',
  marginBottom: '5rem',
  large: {
    fontSize: '$h2',
    lineHeight: '$h2'
  }
});
