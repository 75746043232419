// libraries
import { ReactElement, cloneElement, useLayoutEffect, useRef } from 'react';
import useIsVisible from 'hooks/useIsVisible';

interface Props {
  duration?: number;
  delay?: number;
  stagger?: number;
  visible?: boolean;
  children: ReactElement;
}

export default function DrawSvg(props: Props) {
  const { children, duration = 1000, delay = 0, stagger = 0 } = props;

  const [ref, visible] = useIsVisible<SVGSVGElement>();
  const isVisible = props.visible !== undefined ? props.visible : visible;

  const played = useRef(isVisible);

  useLayoutEffect(() => {
    if (played.current || !ref.current) return;

    ref.current.style.display = '';
    const paths = ref.current?.querySelectorAll<SVGPathElement>('path[stroke]');
    if (!paths) return;

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      const length = path.getTotalLength();

      path.style.transition = path.style.transition = 'none';
      path.style.strokeDasharray = `${length} ${length}`;
      path.style.strokeDashoffset = `${length}`;

      path.getBoundingClientRect();

      path.style.transition = `stroke-dashoffset ${duration}ms ease-in-out`;
      path.style.transitionDelay = `${delay + stagger * i}ms`;

      if (isVisible) {
        path.style.strokeDashoffset = '0';
        played.current = true;
      }
    }
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return cloneElement(children, { ref, style: { display: 'none' } });
}
