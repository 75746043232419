// libraries
import React, { forwardRef, ReactNode } from 'react';

import { styled } from 'style2';

interface Props {
  className?: string;
  title: string;
  actions: ReactNode;
  backgroundColor: string;
  backgroundImage: string;
  backgroundImageRetina: string;
  backgroundSize: string;
  backgroundPosition: string;
  children: ReactNode;
}

export default forwardRef<HTMLDivElement, Props>(function SubFooter(
  props,
  ref
) {
  const {
    className,
    title,
    actions,
    backgroundColor,
    backgroundImage,
    backgroundImageRetina,
    backgroundSize,
    backgroundPosition,
    children
  } = props;

  return (
    <Wrapper
      ref={ref}
      className={className}
      css={{
        background: `${backgroundColor} url(${backgroundImage}) no-repeat`,
        backgroundSize,
        backgroundPosition,
        retina: {
          backgroundImage: `url(${backgroundImageRetina})`
        }
      }}
    >
      <Content>
        <Title>{title}</Title>
        <Actions>{actions}</Actions>
      </Content>

      {children}
    </Wrapper>
  );
});

const Wrapper = styled('section', {
  position: 'relative',
  padding: '14rem 4rem 48rem',
  large: {
    paddingLeft: '8rem',
    paddingRight: '8rem',
    paddingBottom: '36rem'
  },
  full: {
    paddingBottom: '14rem'
  }
});

const Content = styled('div', {
  position: 'relative',
  maxWidth: '120.4rem',
  margin: '0 auto',
  textAlign: 'center',
  zIndex: 1,
  large: {
    textAlign: 'left'
  }
});

const Title = styled('h3', {
  fontSize: '$h3',
  lineHeight: '$h3',
  fontWeight: 'bold',
  marginBottom: '5rem',
  color: '$pitchBlack',
  large:{
    maxWidth: '69.4rem',
  },
  full: {
    fontSize: '$h1',
    lineHeight: '$h1'
  }
});

const Actions = styled('div', {
  display: 'inline-grid',
  gap: '2rem',
  gridAutoFlow: 'row',
  medium: {
    gridAutoFlow: 'column'
  }
});
