// libraries
import React, { ReactNode } from 'react';
import { Global } from '@emotion/core';

import { global } from 'style2';

// components
import Head from 'components/Head';
import Footer from './Footer';

interface Props {
  title?: string;
  description?: string;
  children: ReactNode;
}

export default function Layout(props: Props) {
  const { title, description, children } = props;
  return (
    <>
      <Global styles={global} />
      <Head title={title} description={description} />
      {children}
      <Footer />
    </>
  );
}
